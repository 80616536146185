<template>
  <div class="product-wrap product-farm">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-farm"></div>
    <div class="white-wrap">
      <div class="txt-box align-ct no-indent pd-5">
        <p>智慧农业管理平台利用视频直播溯源技术、区块链防篡改溯源技术、物联网环境监测传感技术， </p>
        <p>智能虫情监测技术、无人机巡航技术、智能灌溉技术、大数据分析技术构建的综合管理系统。 </p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big color-green align-ct"><h4>平台优势</h4></div>
      <div class="auto-img">
        <img src="../../assets/img/product/farm/pic_11.png" alt="平台优势">
      </div>
    </div>
    <div class="white-wrap">
      <div class="fz-big color-green align-ct"><h4>平台架构</h4></div>
      <div class="auto-img">
        <img src="../../assets/img/product/farm/pic_1.png" alt="平台架构">
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big color-green align-ct"><h4>业务系统</h4></div>
        <p class="txt-box">产品追溯系统</p>
        <div class="auto-img">
          <img src="../../assets/img/product/farm/pic_2.png" alt="产品追溯系统">
        </div>
        <p class="txt-box">系统将农业产品全部流程纳入到追溯系统，让农业产品每个生产环节透明可视。农资信息追溯--种植环节--采收环节--加工环节--包装环节--贮藏环节--检疫检验环节--运输环节，全程可追溯。
        </p>
        <div class="auto-img">
          <img src="../../assets/img/product/farm/pic_3.png" alt="产品追溯系统">
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic a-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-green align-lt"><h5>智能灌溉系统</h5></div>
              <div class="txt-box">
                通过智能水肥一体化系统将灌溉与施肥融为一体，借助压力系统，将肥料按土壤养分含量和作物种类的需肥规律和特点，配对成肥液与灌溉水一起，通过管道系统供水、供肥，使水肥想容后通过管道、喷枪或喷头形成喷灌、均匀、定时、定量，喷洒在作物发育生长区域，使生长区域土壤始终保持疏松和适宜的含水量，同时根据不同作物的需肥特点，土壤环境和养分含量状况，需肥规律情况进行不同生育期的需求设计，把水分、养分定时定量，按比例直接提供给作物。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-green align-lt"><h5>TC营销系统</h5></div>
              <div class="txt-box">
                用户通过电商交易系统实现TC用户商品采购，系统中还具备各类营销组件，比如团购、预定、拼单、代金券、线上客服等。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic b-pic" style="margin-top: 10%"></div>
          </el-col>
        </el-row>
      </div>
      <div id="wrbz"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic c-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-green align-lt"><h5>大数据分析展示系统</h5></div>
              <div class="txt-box">
                大数据中心实现总体数据展示、产值数据展示、产量数据展示、GIS种植分布数据展示、生产数据展示、交易数据展示、智能秤数据展示、预警数据展示、农资数据、订单数据、直播数据、环境数据、虫情数据、用户数据、基地数据、位置数据、灌溉数据展示等。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="fqjl"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-green align-lt"><h5>物联网中心</h5></div>
              <div class="txt-box">
                物联网中心实现物联网设备管理，包括直播摄像头、环境监测设备（包含土壤监测、空气温度监测）、虫情监测/预测、灾情（无人机巡航）、智能分拣秤、食品快检设备、大数据展示屏等管理、监测及预警，并实现系统与物联网设备的数据无缝对接，实现基地全面智能化及信息化管理。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic d-pic"></div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-sm color-green align-lt"><h5>园区信息化管理系统</h5></div>
        <div class="txt-box">
          信息化管理包括农事管理、进销存管理等。后台创建标准化种植流程，根据不同时间段发起任务内容，园区管理员通过小程序接收任务并执行，上传执行结果，数据上传至总后台。
        </div>
        <div class="auto-img mt-2">
          <img src="../../assets/img/product/farm/pic_8.png" alt="数字化工厂建设">
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <div class="fz-sm color-green align-lt"><h5>数字化工厂建设</h5></div>
        <div class="txt-box">
          通过数字工厂建设实现压榨车间及灌装车间完成全生产流程数字化管理；通过在压榨车间及灌装车间的不同生产环节安装摄像头、展示大屏，将生产信息及直播信息都同步到每个大屏幕上面，方便领导随时观看及监管。
        </div>
        <el-row :gutter="30" class="mt-5">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="auto-img">
              <img src="../../assets/img/product/farm/pic_9.jpg" alt="数字化工厂建设">
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="auto-img">
              <img src="../../assets/img/product/farm/pic_10.png" alt="数字化工厂建设">
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="nhgl"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-big align-lt color-green" style="padding-top: 8%"><h4>定制化开发</h4></div>
              <div class="txt-box dzh-box">
                <p class="em">定制的不仅仅是软件和硬件 更是一种理念</p>
                <p>定制化开发是解决企业个性化难题唯一途径，而且是企业快速发展和提高竞争力的最好方法。</p>
                <el-button type="success" plain class="zdh-btn" @click="handleShow">立即咨询</el-button>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="auto-img">
              <img src="../../assets/img/product/farm/pic_12.png" alt="定制化">
            </div>
          </el-col>
        </el-row>
        <div class="fz-sm align-ct color-green" style="padding-top: 4%"><h5>定制化特点</h5></div>
        <div class="customized-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gps color-green"></i></div>
            <p>针对性强</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-bianjie color-green"></i></div>
            <p>操作便捷</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-price color-green"></i></div>
            <p>性价比高</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-service color-green"></i></div>
            <p>服务周到</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-upgrade color-green"></i></div>
            <p>升级方便</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧农业-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧农业-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-farm {
  .banner-farm {
    background: url("../../assets/img/product/farm/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;

    .txt {
      width: 20%;
      height: 20%;
      background: url("../../assets/img/product/water/txt.png") no-repeat center;
      background-size: contain;
      position: absolute;
      left: 26%;
      top: 30%;
    }
  }

  .bs-info {
    margin-top: 20%;

    .fz-big {
      padding-bottom: 10%;
    }
  }

  .a-pic {
    background-image: url("../../assets/img/product/farm/pic_4.png");
  }

  .b-pic {
    background-image: url("../../assets/img/product/farm/pic_5.jpg");
  }

  .c-pic {
    background-image: url("../../assets/img/product/farm/pic_6.jpg");
  }

  .d-pic {
    background-image: url("../../assets/img/product/farm/pic_7.png");
  }
}
</style>
